import React from "react";
import './App.css';

const NoCookies = () => {
    return (
        <div className="no-cookies">
            <p>Kohde ei ole näkyvissä evästeasetustenne vuoksi.</p>
        </div>
    );
}

export default NoCookies;